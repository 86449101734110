import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputFecha from 'components/BasicComponents/Inputs/InputFecha';
import InputNumber from 'components/BasicComponents/Inputs/InputNumber';
import InputText from 'components/BasicComponents/Inputs/InputText';
import TextArea from 'components/BasicComponents/Inputs/TextArea';
import WasteFlowCarrierSelector from 'components/ComplexComponents/WasteFlowCarrierSelector';
import { DiExtractionWarningFields } from 'modules/di-extraction';
import { usePromotion } from 'modules/promotion';
import { TraceabilitySaver } from 'modules/traceabilities';
import './TraceabilityFieldset.scss';

export interface TraceabilityFieldsetProps {
    initialTraceabilityData: TraceabilitySaver;
    getInputExtraElement?: (field: DiExtractionWarningFields, value: any) => JSX.Element | null;
    onChange?: (traceabilityData: Partial<TraceabilitySaver>) => void;
    error?: Error | null;
}

const TraceabilityFieldset = (props: TraceabilityFieldsetProps) => {
    const [t] = useTranslation();
    const [{ promotion }] = usePromotion();
    const [traceability, setTraceability] = useState(props.initialTraceabilityData);

    const updateTraceability = (newData: TraceabilitySaver) => {
        setTraceability(newData);
        props.onChange?.(newData);
    };

    const getUpdatedCost = () => {
        const { ppgcl, weight } = traceability;

        const cost = (weight || 0) * (ppgcl?.cost?.ton || 0) + (ppgcl?.cost?.unit || 0);
        return cost;
    };

    const handleInputChange = ({ target }) => {
        const { name, value } = target;
        const newValue = (name === 'cost' || name === 'volume') && !value ? null : value;

        updateTraceability({ ...traceability, [name]: newValue });
    };

    const handleIncompleteCheckChange = ({ target }) => {
        updateTraceability({ ...traceability, incomplete: target.checked });
    };

    useEffect(() => {
        if (!props.initialTraceabilityData) return;
        setTraceability(props.initialTraceabilityData);
    }, [JSON.stringify(props.initialTraceabilityData || {})]);

    useEffect(() => {
        if (!traceability.ppgcl) return;
        const updatedFields = { cost: getUpdatedCost(), volume: traceability.ppgcl?.volume || null };
        updateTraceability({ ...traceability, ...updatedFields });
    }, [JSON.stringify(traceability.ppgcl)]);

    useEffect(() => {
        if (!traceability.ppgcl) return;

        const updatedCost = getUpdatedCost();
        updateTraceability({ ...traceability, cost: updatedCost });
    }, [JSON.stringify(traceability.weight)]);

    if (!promotion) return null;

    return (
        <div className="TraceabilityFieldset">
            <div className="TraceabilityFieldset__row">
                <WasteFlowCarrierSelector
                    promotionId={promotion?.id}
                    flow={traceability.ppgcl}
                    onFlowChange={(flow) => updateTraceability({ ...traceability, ppgcl: flow || null })}
                >
                    {props.getInputExtraElement?.('ppgcl', traceability.ppgcl)}
                </WasteFlowCarrierSelector>

                <div className="TraceabilityFieldset__break" />

                <InputFecha
                    inputContainer="TraceabilityFieldset__input-date"
                    className="TraceabilityFieldset__input"
                    label={t('date.date', { ns: 'common' })}
                    name="date"
                    value={traceability.date}
                    values={traceability}
                    setValues={updateTraceability}
                    onChange={handleInputChange}
                    validacion={!!props.error}
                    maxDays={0}
                    extraElement={props.getInputExtraElement?.('date', traceability.date)}
                    required
                />

                <InputText
                    inputContainer="TraceabilityFieldset__input-numDi"
                    className="TraceabilityFieldset__input"
                    label={t('di.number', { ns: 'traceability' })}
                    name="numDi"
                    value={traceability.numDi}
                    onChange={handleInputChange}
                    validacion={!!props.error}
                    extraElement={props.getInputExtraElement?.('numDi', traceability.numDi)}
                />

                <div className="TraceabilityFieldset__break" />

                <InputNumber
                    className="TraceabilityFieldset__input TraceabilityFieldset__input-small"
                    label={t('weight.upperCase', { ns: 'common' }) + ' (T)'}
                    name="weight"
                    id="weight"
                    value={traceability.weight || ''}
                    showThousandSeparator={false}
                    onChange={handleInputChange}
                    validacion={!!props.error}
                    extraElement={props.getInputExtraElement?.('weight', traceability.weight)}
                    required
                    maximumFractionDigits={3}
                    min="1"
                />

                {promotion?.permissions.costs && (
                    <div className="TraceabilityFieldset__field TraceabilityFieldset__cost">
                        <InputNumber
                            className="TraceabilityFieldset__input TraceabilityFieldset__input-small"
                            label={t('cost.upperCase', { ns: 'costs' }) + ' (€)'}
                            name="cost"
                            value={traceability.cost || ''}
                            onChange={handleInputChange}
                            {...({} as any)}
                        />
                        <p>
                            {!!traceability?.ppgcl?.cost?.unit && <p>Unitario: {traceability?.ppgcl?.cost?.unit}€</p>}
                            {!!traceability?.ppgcl?.cost?.ton && <p>Tonelada: {traceability?.ppgcl?.cost?.ton}€</p>}
                        </p>
                    </div>
                )}

                <InputNumber
                    className={`TraceabilityFieldset__input TraceabilityFieldset__input-small`}
                    label={t('volume.upperCase', { ns: 'traceability' }) + ' (m³)'}
                    name="volume"
                    value={traceability.volume || ''}
                    onChange={handleInputChange}
                    // Just to ignore optional fields
                    {...({} as any)}
                />

                <InputText
                    label={t('detail.vehiclePlate', { ns: 'traceabilities' })}
                    name="vehiclePlate"
                    inputContainer="TraceabilityFieldset__input-vehiclePlate"
                    className="TraceabilityFieldset__input"
                    value={traceability.vehiclePlate || ''}
                    onChange={handleInputChange}
                    {...({} as any)}
                />

                <div className="TraceabilityFieldset__break TraceabilityFieldset__break--always" />

                <label className="TraceabilityFieldset__incompleteCheck">
                    <input
                        type="checkbox"
                        checked={traceability.incomplete}
                        onChange={(event) => handleIncompleteCheckChange(event)}
                    />
                    <span>DI incompleto o incorrecto</span>
                </label>

                <div className="TraceabilityFieldset__break TraceabilityFieldset__break--always" />

                <TextArea
                    label={t('detail.observations', { ns: 'traceabilities' })}
                    name="observations"
                    value={traceability.observations}
                    onChange={handleInputChange}
                    className="TraceabilityFieldset__input TraceabilityFieldset__observations"
                    inputContainer="TraceabilityFieldset__input-fullWidth"
                    {...({} as any)}
                />
            </div>
        </div>
    );
};

export default TraceabilityFieldset;
