import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { usePromotion } from 'modules/promotion';
import { TraceabilitySaverExtended, useSaveTraceability, useTraceabilityToEdit } from 'modules/traceabilities';
import { getEmptyTraceabilityToEdit } from 'modules/traceabilities/domain/utils/traceabilities.helpers';
import { notify } from 'utils/helpers/notifications';
import { TraceabilityFormProps } from '../TraceabilityForm';

export function useTraceabilityForm(props: TraceabilityFormProps) {
    const [t] = useTranslation('traceabilities');
    const [, analytics] = useAnalyticsStore();
    const [{ promotion }] = usePromotion();
    const { data, loading, error, refetch } = useTraceabilityToEdit({
        promotionId: promotion?.id,
        id: props.traceabilityId?.toString()
    });

    const [traceability, setTraceability] = useState<TraceabilitySaverExtended>(getEmptyTraceabilityToEdit());

    const saveTraceability = useSaveTraceability();

    const save = async (overrides?: Partial<TraceabilitySaverExtended>) => {
        const payload = { ...traceability, ...(overrides || {}) };
        const result = await saveTraceability.mutate(payload);

        if (props.traceabilityId !== undefined) {
            analytics.event(AnalyticsEvents.TRACEABILITY_CREATED_MANUALLY);
        } else {
            analytics.event(AnalyticsEvents.TRACEABILITY_EDITED_MANUALLY);
        }

        return result;
    };

    const reset = () => {
        setTraceability(getEmptyTraceabilityToEdit());
    };

    useEffect(() => {
        setTraceability(data?.id ? data : getEmptyTraceabilityToEdit());
    }, [data?.id]);

    useEffect(() => {
        if (props.disableNotifyOnSave) return;
        if (saveTraceability.loading === 'succeeded') {
            const nextStep = traceability.id === undefined ? reset : refetch;
            nextStep();
        }
        const msg = t(`form.save.${saveTraceability.loading}`, { defaultValue: null });
        if (!msg) return;

        notify({ msg, isSuccess: saveTraceability.loading === 'succeeded' });
    }, [saveTraceability.loading]);

    return {
        traceability,
        setTraceability,
        refetch,
        save,

        error: error || saveTraceability.error,
        isPending: loading === 'pending' || saveTraceability.loading === 'pending'
    };
}
