import axios from 'axios';
import { getToken } from '../../utils/helpers/api.helpers';

class CostsServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    createCost = async ({ codLer, coste, observaciones, promocion, fecha, gestora }) => {
        try {
            const response = await this.services.post(
                `/api/tracing/promotion-costs`,
                {
                    codLer,
                    coste,
                    observaciones,
                    promocion,
                    fecha,
                    gestora
                },
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    getCosts = async (promotionId, filtro) => {
        try {
            const result = await this.services.get(`/api/costs/promotion/${promotionId}${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    countCosts = async (promotionId, filtro) => {
        try {
            const result = await this.services.get(`/api/costs/promotion/${promotionId}/count${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    getTotalCostsPromotion = async (promotionId, filtro) => {
        try {
            const result = await this.services.get(`/api/costs/promotion/${promotionId}/total${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    getCostById = async (promotionId, id) => {
        try {
            const result = await this.services.get(`/api/costs/promotion/${promotionId}?id.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data?.[0];
        } catch (error) {
            console.log(error);
        }
    };

    deleteCost = async (id) => {
        try {
            const result = await this.services.delete(`/api/tracing/promotion-costs/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    updateCost = async (cost) => {
        try {
            const result = await this.services.put(`/api/tracing/promotion-costs`, cost, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };
}

const costsServices = new CostsServices();
export default costsServices;
