import axios from 'axios';
import { dispatchLogout } from '../../lib/LogoutHandler';
import { getToken } from '../../utils/helpers/api.helpers';

class ActivitiesServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    createActivity = async (actividad) => {
        try {
            const response = await this.services.post(`/api/tracing/promotion-activity`, actividad, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getActivities = async (filtro) => {
        try {
            const result = await this.services.get(`/api/tracing/promotion-activity${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getActivityById = async (id) => {
        try {
            const result = await this.services.get(`/api/tracing/promotion-activity?id.equals=${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data?.[0];
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deleteActivity = async (id) => {
        try {
            const result = await this.services.delete(`/api/tracing/promotion-activity/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    updateActivity = async (actividad) => {
        try {
            const result = await this.services.put(`/api/tracing/promotion-activity`, actividad, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getTypesActivities = async (filtro) => {
        try {
            const result = await this.services.get(`/api/promotion-activity-type${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    createActivityFile = async ({ actividad, fichero, ficheroContentType, nombreFichero }) => {
        try {
            const response = await this.services.post(
                `/api/tracing/promotion-activity-documents`,
                {
                    actividad,
                    fichero,
                    ficheroContentType,
                    nombreFichero
                },
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return response?.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    getActivityFile = async (filtro) => {
        try {
            const result = await this.services.get(`/api/tracing/promotion-activity-documents${filtro}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };

    deleteActivityFile = async (id) => {
        try {
            const result = await this.services.delete(`/api/tracing/promotion-activity-documents/${id}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            if (error.response.status === 401) {
                dispatchLogout();
            }
        }
    };
}

const activitiesServices = new ActivitiesServices();
export default activitiesServices;
