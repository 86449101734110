import { useTranslation } from 'react-i18next';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { TituloH1 } from '../../../components/BasicComponents/Titulos/Titulos';
import ImpactData from './ImpactData';
import './Impact.scss';

const ImpactPage = () => {
    const [t] = useTranslation();

    return (
        <Layout>
            <TituloH1 titulo={t('impact', { ns: 'common' })} />
            <ImpactData />
        </Layout>
    );
};

export default withAuth(ImpactPage);
