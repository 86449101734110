import { Link } from 'react-router-dom';
import { IconDotsVertical, IconEye, IconPencil, IconTrash } from '@tabler/icons';

import { useTranslation } from 'react-i18next';
import { Traceability, useTraceabilities } from 'modules/traceabilities';
import DropdownList from 'components/BasicComponents/DropdownList/DropdownList';
import ActionConfirm from 'components/BasicComponents/ActionConfirm/ActionConfirm';
import { TraceabilitiesRemoveOperations } from 'modules/traceabilities/domain/TraceabilitiesOperations';
import { usePromotion } from 'modules/promotion/infrastructure/react/promotionContext';

import { COLUMNS } from './constants/columns';

interface ActionsColumnProps {
    traceability: Traceability;
}

const iconSize = 18;

const ActionsColumn = ({ traceability }: ActionsColumnProps) => {
    const [{ promotion }] = usePromotion();
    const promotionId = promotion?.id;

    const [, { removeOneTraceability }] = useTraceabilities();
    const [t] = useTranslation();

    const columnData = COLUMNS.find((column) => column.name === 'actions') || null;
    if (!columnData) return null;

    const remove = async (operation: TraceabilitiesRemoveOperations) => {
        await removeOneTraceability(traceability, operation);
    };

    return (
        <td className="TraceabilitiesTable__actions">
            <DropdownList
                items={[
                    <Link
                        className="TraceabilitiesTable__action"
                        to={`/promotion/${promotionId}/traceabilities/${traceability.id}`}
                    >
                        <IconEye size={iconSize} /> {t(`operations.SHOW_DETAIL.name`, { ns: 'traceabilities' })}
                    </Link>,
                    traceability.canEdit ? (
                        <Link
                            className="TraceabilitiesTable__action"
                            to={`/promotion/${promotionId}/traceabilities/${traceability.id}/edit`}
                        >
                            <IconPencil size={iconSize} /> {t(`operations.EDIT.name`, { ns: 'traceabilities' })}
                        </Link>
                    ) : null,
                    traceability.canDelete ? (
                        <ActionConfirm
                            title={t(`operations.REMOVE.name`, { ns: 'traceabilities' })}
                            message={t(`operations.REMOVE.confirmationMessage`, { ns: 'traceabilities' })}
                            onConfirm={() => remove(TraceabilitiesRemoveOperations.REMOVE)}
                        >
                            <button
                                className="TraceabilitiesTable__action"
                                data-testid="TraceabilitiesTable__action--remove"
                            >
                                <IconTrash size={iconSize} /> {t(`operations.REMOVE.name`, { ns: 'traceabilities' })}
                            </button>
                        </ActionConfirm>
                    ) : null
                ]}
            >
                <button className="TraceabilitiesTable__openActions TraceabilitiesTable__button">
                    <IconDotsVertical size={20} />
                </button>
            </DropdownList>
        </td>
    );
};

export default ActionsColumn;
