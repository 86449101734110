import { IconEdit } from '@tabler/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import ActionConfirm from 'components/BasicComponents/ActionConfirm/ActionConfirm';
import RefuseButton from 'components/BasicComponents/Buttons/RefuseButton';
import SecondaryButton from 'components/BasicComponents/Buttons/SecondaryButton';
import { TituloH1 } from 'components/BasicComponents/Titulos/Titulos';
import GoBack from 'components/BasicComponents/Buttons/GoBack';
import TraceabilityDetail from 'features/traceabilities/components/TraceabilityDetail/TraceabilityDetail';
import { useTraceabilities } from 'modules/traceabilities';
import { TraceabilitiesRemoveOperations } from 'modules/traceabilities/domain/TraceabilitiesOperations';
import Layout from 'pages/Layout/Layout';

import './TraceabilityDetailPage.scss';

const TraceabilityDetailPage = () => {
    const { traceabilityId, promotionId } = useParams<{ traceabilityId: string; promotionId: string }>();
    const [traceabilities, { removeOneTraceability }] = useTraceabilities();
    const history = useHistory();
    const [t] = useTranslation();

    const traceability = traceabilities.data.byId[traceabilityId];

    const removeAndGoBack = (operation: TraceabilitiesRemoveOperations) => {
        if (!traceability) return;
        removeOneTraceability(traceability, operation);

        const url = `/promotion/${promotionId}/traceabilities`;
        history.push(url);
    };

    return (
        <Layout>
            <div className="TraceabilityDetailPage">
                <header className="TraceabilityDetailPage__header">
                    <div>
                        <GoBack goBack={() => history.goBack()} />
                        <TituloH1 titulo={'#' + traceabilityId} />
                    </div>

                    <div className="TraceabilityDetailPage__actions">
                        {traceability?.canDelete && (
                            <ActionConfirm
                                title={t(`operations.REMOVE.name`, { ns: 'traceabilities' })}
                                message={t(`operations.REMOVE.confirmationMessage`, { ns: 'traceabilities' })}
                                onConfirm={() => removeAndGoBack(TraceabilitiesRemoveOperations.REMOVE)}
                            >
                                <RefuseButton
                                    type="button"
                                    text={t('operations.REMOVE.name', { ns: 'traceabilities' })}
                                    action={() => removeAndGoBack(TraceabilitiesRemoveOperations.REMOVE)}
                                    // Just to ignore optional fields
                                    {...({} as any)}
                                />
                            </ActionConfirm>
                        )}
                        {traceability?.canEdit && (
                            <SecondaryButton
                                type="link"
                                titulo={t('operations.EDIT.name', { ns: 'traceabilities' })}
                                icon={<IconEdit size={24} />}
                                iconPosition="left"
                                to={`/promotion/${promotionId}/traceabilities/${traceability.id}/edit`}
                                // Just to ignore optional fields
                                {...({} as any)}
                            />
                        )}
                    </div>
                </header>

                <TraceabilityDetail traceabilityId={traceabilityId} />
            </div>
        </Layout>
    );
};

export default TraceabilityDetailPage;
