import fetcher from 'lib/fetcher';
import { AnalyticsEvents, useAnalyticsStore } from 'modules/analytics';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import GoBack from '../../../components/BasicComponents/Buttons/GoBack';
import SubmitButton from '../../../components/BasicComponents/Buttons/SubmitButton';
import SimpleCard from '../../../components/BasicComponents/Cards/SimpleCard';
import DragNDrop from '../../../components/BasicComponents/DragAndDrop/DragNDrop';
import InputFecha from '../../../components/BasicComponents/Inputs/InputFecha';
import InputNumber from '../../../components/BasicComponents/Inputs/InputNumber';
import TextArea from '../../../components/BasicComponents/Inputs/TextArea';
import MessageNotComplete from '../../../components/BasicComponents/Messages/MessageNotComplete';
import Toast from '../../../components/BasicComponents/Messages/Toast';
import CodLerAutocomplete from '../../../components/BasicComponents/Select-Autocomplete/CodLerAutocomplete';
import Spinner from '../../../components/BasicComponents/Spinner/Spinner';
import { TituloH1, TituloH4 } from '../../../components/BasicComponents/Titulos/Titulos';
import { withAuth } from '../../../context/AuthProvider';
import Layout from '../../../pages/Layout/Layout';
import { allFieldsComplete, keyValueObjNotEmpty } from '../../../utils/helpers/general.helpers';
import { deleteDocument } from '../../documentation/api/deleteDocument';
import { getMultiplesDocuments } from '../../documentation/api/getMultiplesDocuments';
import { traceabilityDocuments } from '../../documentation/api/traceabilityDocuments';
import { REUSE_CERTIFICATE_TYPE_ID } from '../../documentation/constants/documentation.constants';
import { createReuse } from '../api/createReuse';
import { updateReuse } from '../api/updateReuse';
import { newReuseCertificate } from '../helpers/reuse.helpers';
import { mapperPostReuse, mapperTraceabilityToReuse } from '../helpers/reuseMappers';
import './NewReuse.scss';

const NewReusePage = (props) => {
    const [t] = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const [, analytics] = useAnalyticsStore();

    const INITIAL_VALUES = {
        selfTraceabilityId: null,
        promotionId: props?.promocion?.id,
        date: '',
        codLer: '',
        weight: '',
        volume: '',
        observations: '',
        documentoPromos: []
    };

    const [editingMode, setEditingMode] = useState(false);
    const [values, setValues] = useState(INITIAL_VALUES);
    const [loading, setLoading] = useState(false);
    const [invalidForm, setInvalidForm] = useState(false);
    const [files, setFiles] = useState([]);
    const [oldCertificate, setOldCertificate] = useState(null);

    const [messageNotComplete, setMessageNotComplete] = useState();

    /* TODO: Temporary ÑDD. Delete when 'reuse' feature is refactored */
    const traceabilityById = async (id) => {
        try {
            const response = await fetcher.get(`/api/tracing/container-traceability?id.equals=${id}`);
            return response?.data?.content?.traceabilities[0];
        } catch (error) {
            console.log(error);
            return undefined;
        }
    };

    useEffect(() => {
        if (props?.promocion?.id !== undefined) {
            setValues({ ...values, promotionId: props?.promocion?.id });

            if (props.match.params?.id !== undefined) {
                setEditingMode(true);
                getTraceabilityData();
            }
        }
    }, [props?.promocion]);

    const getTraceabilityData = async () => {
        const traceability = await traceabilityById(props.match.params?.id);
        if (!traceability) return;

        const mappedReuse = mapperTraceabilityToReuse(traceability);

        const documents = await getDocuments();
        mappedReuse['documentoPromos'] = documents;

        setValues(mappedReuse);
    };

    const getDocuments = async () => {
        const documents = await traceabilityDocuments(props.match.params.id);

        if (documents?.data?.length > 0) {
            const idsDocuments = documents?.data?.map((documento) => documento.documentId);
            const allDocuments = await getMultiplesDocuments(props.promocion?.id, idsDocuments);
            if (allDocuments?.data?.code == 0) {
                const certificate = allDocuments?.data?.content?.documents.find(
                    (document) => document.type.id === REUSE_CERTIFICATE_TYPE_ID
                );
                setOldCertificate(certificate);
                setFiles([certificate]);
                return allDocuments?.data?.content?.documents;
            }
        }
        return [];
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (validateForm()) {
            const reuseRequest = await getReuseRequest();

            if (reuseRequest?.data?.code == 0 || reuseRequest?.status == 204 || reuseRequest?.status == 200) {
                await createAndAssociateCertificate(reuseRequest);

                if (editingMode) {
                    setTimeout(() => goBack(), 1000);
                } else {
                    setValues(INITIAL_VALUES);
                    setFiles([]);
                }
            }
        }

        setLoading(false);
    };

    const getReuseRequest = () => {
        const newReuseDataMapped = mapperPostReuse(values);
        if (editingMode) {
            return updateReuse(newReuseDataMapped);
        } else {
            analytics.event(AnalyticsEvents.REUSE_CREATED);
            return createReuse(newReuseDataMapped);
        }
    };

    const handleInputChange = ({ target }) => {
        setValues({
            ...values,
            [target.name]: target.value
        });
    };

    const requiredFields = {
        promotionId: values?.promotionId,
        date: values?.date,
        codLer: values?.codLer?.id,
        weight: values?.weight,
        volume: values?.volume
    };

    const validateForm = () => {
        if (allFieldsComplete(requiredFields)) {
            setMessageNotComplete('');
            setInvalidForm(false);
            return true;
        } else {
            setMessageNotComplete(t('notComplete', { ns: 'errors' }));
            setInvalidForm(true);
            return false;
        }
    };

    const removeDocument = () => {
        setValues({ ...values, documentoPromos: values?.documentoPromos?.filter((doc) => doc.id !== files[0]?.id) });
        setFiles([]);
    };

    const createAndAssociateCertificate = async (reuseRequest) => {
        if (files?.length > 0 && !files[0]?.id) {
            await newReuseCertificate({
                files,
                values,
                traceabilityId: reuseRequest?.data?.content?.id || values?.selfTraceabilityId,
                promoId: props.promocion?.id
            });
        }

        if (oldCertificate?.id !== undefined && (oldCertificate?.id !== files[0]?.id || files?.length === 0)) {
            await deleteDocument(oldCertificate?.id);
        }
    };

    const goBack = () => {
        const pathname = `/promotion/${props.promocion.id}/traceabilities`;

        if (!location.state?.values) {
            history.push(pathname);
        } else {
            history.push({
                pathname,
                state: keyValueObjNotEmpty(location.state?.values) && { values: location.state.values }
            });
        }
    };

    return (
        <Layout>
            <GoBack goBack={goBack} />
            <TituloH1 titulo={editingMode ? t('editReuse', { ns: 'reuse' }) : t('newReuse', { ns: 'reuse' })} />
            <SimpleCard>
                <form onSubmit={handleFormSubmit} className="newReuse">
                    <p>{t('percentageReuse', { ns: 'reuse' })}</p>

                    <InputFecha
                        label={t('date.date', { ns: 'common' })}
                        id="fecha-nueva-trazabilidad"
                        name="date"
                        value={values?.date}
                        onChange={handleInputChange}
                        validacion={invalidForm}
                        required
                    />
                    <div className="newReuse__row">
                        <CodLerAutocomplete
                            {...{ values, setValues }}
                            value={values?.codLer}
                            required
                            validacion={invalidForm}
                            searchFromPgcl={true}
                            pgclValorizationFilter="&valorizationCode.equals=RU0"
                        />

                        <InputNumber
                            label={t('managed.tn', { ns: 'traceability' })}
                            id="tn-gestionadas-nueva-traz"
                            name="weight"
                            value={values?.weight}
                            onChange={handleInputChange}
                            validacion={invalidForm}
                            required
                            maximumFractionDigits={3}
                        />
                        <InputNumber
                            label={t('volume.upperCase', { ns: 'traceability' })}
                            id="volumen-nueva-traz"
                            name="volume"
                            value={values?.volume}
                            onChange={handleInputChange}
                            min="0"
                            validacion={invalidForm}
                            required
                        />
                    </div>

                    <div className="newReuse__row newReuse__textarea">
                        <TextArea
                            label={t('comments', { ns: 'common' })}
                            id="observaciones-di"
                            name="observations"
                            value={values?.observations}
                            onChange={handleInputChange}
                            inputContainer="input__container"
                        />
                    </div>

                    <div className="newReuse__row">
                        <div>
                            <TituloH4 titulo={t('reuseCertificate', { ns: 'reuse' })} />
                            <DragNDrop
                                label={''}
                                maxFiles={1}
                                setFiles={setFiles}
                                files={files}
                                urlProp="url"
                                fileNameProp="name"
                                fileProp="bytes"
                                contentTypeProp="type"
                                removeFunction={removeDocument}
                            />
                        </div>
                    </div>

                    <SubmitButton text={t('save', { ns: 'common' })} buttonDisabled={loading ? true : false} />
                </form>
                {messageNotComplete && <MessageNotComplete message={messageNotComplete} />}
                {loading && <Spinner />}
                <Toast />
            </SimpleCard>
        </Layout>
    );
};
export default withAuth(NewReusePage);
