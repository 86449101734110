export const mapperPostReuse = ({
    selfTraceabilityId,
    promotionId,
    date,
    weight,
    volume,
    observations,
    codLer,
    documentoPromos
}) => {
    const reuse = {
        selfTraceabilityId,
        promotionId,
        date,
        weight,
        volume,
        observations,
        codLer: codLer?.codigoLER,
        documentoPromos
    };

    return reuse;
};

export const mapperTraceabilityToReuse = (traceability) => {
    const reuse = {
        ...traceability,
        selfTraceabilityId: traceability?.id,
        promotionId: traceability?.promoProGesCodeLer?.promocion?.id,
        date: traceability?.fechaMovimiento,
        weight: traceability?.pesoGestionado,
        volume: traceability?.volumenGestionado,
        observations: traceability?.observation,
        codLer: traceability?.promoProGesCodeLer?.wasteManagerProGesCodLer?.codLER
    };

    return reuse;
};
