import axios from 'axios';
import fetcher from 'lib/fetcher';
import { getToken } from '../../utils/helpers/api.helpers';

const _message = {
    success: {
        text: 'successCreatePromotion',
        ns: 'newPromotion'
    },
    error: {
        text: 'errorCreatePromotion',
        ns: 'newPromotion'
    }
};

class PromotionServices {
    constructor() {
        this.services = axios.create({
            baseURL: process.env.REACT_APP_API_URL,
            withCredentials: true
        });
    }

    getUserPromotions = async (id) => {
        try {
            const response = await this.services.get(
                `/api/maintenance/promotions?usuarioId.equals=${id}&sort=nombre,asc`,
                {
                    headers: {
                        Authorization: getToken()
                    }
                }
            );
            return response;
        } catch (error) {
            console.log(error);
        }
    };

    filterPromotion = async (url) => {
        try {
            const response = await this.services.get(`/api/maintenance/promotions${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    getPromotionById = async (url) => {
        try {
            const response = await this.services.get(`/api/maintenance/promotions?id.equals=${url}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response?.data?.[0];
        } catch (error) {
            console.log(error);
        }
    };

    filterPromotionName = async (name) => {
        try {
            const response = await this.services.get(`/api/maintenance/promotions?nombre.contains=${name}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return response.data;
        } catch (error) {
            console.log(error);
        }
    };

    promotionType = async (filter) => {
        try {
            const result = await this.services.get(`/api/maintenance/promotion-type${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    promotionState = async (filter = '') => {
        try {
            const result = await this.services.get(`/api/maintenance/promotion-status${filter}`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result.data;
        } catch (error) {
            console.log(error);
        }
    };

    createPromotion = async (promotion) => {
        const body = {
            ...promotion,
            nima: promotion.nima || null
        };

        const result = await fetcher.post(`/api/maintenance/promotions`, body, {
            headers: {
                Authorization: getToken()
            },
            message: _message
        });
        return result;
    };

    editPromotion = async (promotion) => {
        const body = {
            ...promotion,
            nima: promotion.nima || null
        };

        try {
            const result = await fetcher.put(`/api/maintenance/promotions`, body, {
                headers: {
                    Authorization: getToken()
                },
                message: _message
            });
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    requestNewPromotion = async (promotion) => {
        try {
            const result = await this.services.post(`/api/maintenance/request-new-promotion`, promotion, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    getAllPromotionBilleables = async () => {
        try {
            const result = await this.services.get(`/api/maintenance/promotion-addons`, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data;
        } catch (error) {
            console.log(error);
        }
    };

    addAddonPromotion = async (addon) => {
        try {
            const result = await this.services.put(`/api/maintenance/add-addon-to-promotion`, addon, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data;
        } catch (error) {
            console.log(error);
        }
    };

    removeAddonPromotion = async (addon) => {
        try {
            const result = await this.services.put(`/api/maintenance/remove-addon-from-promotion`, addon, {
                headers: {
                    Authorization: getToken()
                }
            });
            return result?.data;
        } catch (error) {
            console.log(error);
        }
    };
}

const promotionServices = new PromotionServices();
export default promotionServices;
