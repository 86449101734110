import Traceabilities from '../index';
import TraceabilitiesPage from '../pages/TraceabilitiesPage/TraceabilitiesPage';
import TraceabilityDetailPage from '../pages/TraceabilityDetailPage';
import TraceabilityEditPage from '../pages/TraceabilityEditPage';

export const traceabilitiesRouteRoot = '/promotion/:promotionId/traceabilities';

export const TRACEABILITIES_VIEWS = {
    automaticReading: '/review',
    wasteRegister: '/waste-register'
};

export const INTERNAL_TRACEABILITIES_ROUTES = [
    {
        component: TraceabilityEditPage,
        path: traceabilitiesRouteRoot + '/create',
        requiredPermission: 'canCreate',
        section: 'traceability',
        exact: true
    },
    {
        component: TraceabilityEditPage,
        path: traceabilitiesRouteRoot + '/:traceabilityId/edit',
        requiredPermission: 'canCreate',
        section: 'traceability',
        exact: true
    },
    {
        component: TraceabilitiesPage,
        path: traceabilitiesRouteRoot + '/review',
        exact: true
    },
    {
        component: TraceabilitiesPage,
        path: traceabilitiesRouteRoot + '/waste-register',
        exact: false // we have /waste-register/list and /waste-register/table
    },
    {
        component: TraceabilitiesPage,
        path: traceabilitiesRouteRoot,
        exact: true
    },
    // Do not put this route one level above, as it will break /review and /waste-register routes
    {
        component: TraceabilityDetailPage,
        path: traceabilitiesRouteRoot + '/:traceabilityId',
        exact: true
    }
];

export const TRACEABILITIES_ROUTES = [
    {
        component: Traceabilities,
        path: traceabilitiesRouteRoot,
        requiredPermission: 'canView',
        section: 'traceability',
        exact: false
    }
];
